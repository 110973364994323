<template>
  <div class="details">
    <div class="videoBox">
      <video
        ref="video"
        autoplay
        controls
        preload="auto"
        :poster="store.actVideoDec.videoThumbnail"
        :src="store.actVideoDec.videoUrl"
        controlslist="noplaybackrate"
      ></video>
      <div class="mActList">
        <div class="item" @click="actItem(1)" :class="{ act: act == 1 }">
          简介
        </div>
        <div class="item" @click="actItem(2)" :class="{ act: act == 2 }">
          评论
        </div>
      </div>
    </div>
    <div class="box1" v-if="act == 1">
      <div class="videoDetails">
        <div class="user">
          <div class="name">
            <!-- 用户名称 -->
            {{ store.actVideoDec.userNickName || "user" }}
          </div>
          <div class="mBut">
            <div
              class="wl-but"
              v-if="store.actVideoDec.followed == 0"
              @click="addGZ()"
            >
              关注
            </div>
            <div
              class="wl-but but2"
              v-if="store.actVideoDec.followed == 1"
              @click="delGZ()"
            >
              已关注
            </div>
          </div>
        </div>
        <div class="videoName">
          <!-- 视频标题 -->
          {{ store.actVideoDec.videoTitle }}
        </div>
        <!-- <div class="videoJS">
          大家听我说，在座的各位都是垃圾。大家听我说，在座的各位都是垃圾。123
        </div> -->
      </div>
      <!-- 操作 -->
      <div class="operate">
        <div class="like" @click="vant()">
          <svg v-if="ifVant" class="icon svg-icon" aria-hidden="true">
            <use xlink:href="#icon-dianzan"></use>
          </svg>
          <svg v-else class="icon svg-icon" aria-hidden="true">
            <use xlink:href="#icon-dianzan_kuai"></use>
          </svg>
          <span>点赞</span>
        </div>
        <div class="coin" @click="upReward()">
          <svg class="icon l" aria-hidden="true">
            <use xlink:href="#icon-meiyoudashang"></use>
          </svg>
          <span>打赏</span>
        </div>
        <div class="watch" @click="addLove()">
          <svg
            v-if="store.actVideoDec.collected == 0"
            class="icon svg-icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-shoucang_shixin"></use>
          </svg>
          <svg
            v-if="store.actVideoDec.collected == 1"
            class="icon svg-icon tru"
            aria-hidden="true"
          >
            <use xlink:href="#icon-shoucang_shixin"></use>
          </svg>
          <span>收藏</span>
        </div>
        <div
          class="watch hash"
          href="javascript:;"
          :data-clipboard-text="
            'http://polycollege.club/video?' + store.actVideoId
          "
          @click="doCopy()"
        >
          <svg class="icon l" aria-hidden="true">
            <use xlink:href="#icon-fenxiang"></use>
          </svg>
          <span>分享</span>
        </div>
      </div>
      <!-- <div class="listName">相关</div>
      <div class="list" v-if="false">
        <video-item></video-item>
        <video-item></video-item>
        <video-item></video-item>
        <video-item></video-item>
      </div> -->
    </div>
    <div class="box2" v-else>
      <video-details-pl></video-details-pl>
    </div>
    <reward ref="rew"></reward>
  </div>
</template>
<script>
import Store from "../../store";
import videoDetailsPl from "./video-details-pl.vue";
import Clipboard from "clipboard";
import vantABI from "../../components/video/vantABI";
import Reward from "../../components/video/reward.vue";
// import VideoItem from "./video-item.vue";
export default {
  components: {
    videoDetailsPl,
    Reward,
    // VideoItem
  },
  mounted() {
    // 获取传参
    let id = window.location.search.substr(1);
    if (id != "" && id.length > 5) {
      this.store.actVideoId = id;
    }
    this.setPlaybackVolume();
    this.getVideoDec();
    this.video = this.$refs.video;
    // 构建网络
    this.web3 = new this.$web3("https://seed1.PolySmartChain.com");
    // 构建点赞合约
    this.vantCont = new this.web3.eth.Contract(vantABI, this.store.vantCont);
    // 查询是否点过攒
    this.getIfVant();
  },
  data() {
    return {
      video: "",
      store: Store.state,
      uti: Store.uti,
      act: 1,
      // 是否不可以点赞
      ifVant: false,
    };
  },
  methods: {
    // 暂停开始
    pauseStarts() {
      console.log(this.$refs.video.paused);
      if (this.video.paused) {
        this.video.play();
      } else {
        this.video.pause();
      }
    },
    // 选择评论还是详情
    actItem(val) {
      this.act = val;
    },
    qp() {
      var el = document.documentElement;
      var rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      }
    },
    // 获取视频详情
    async getVideoDec() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/videoInfo/videoDetail/${this.store.user.userId || 0}/${
          this.store.actVideoId
        }`
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      // this.$message.success("操作成功");
      this.store.actVideoDec = res.data;
      // this.getW();
    },
    // 记录播放量
    async setPlaybackVolume() {
      let { data: res } = await this.$http.post(
        `/videoInfo/video/add/play/${this.store.actVideoId}`
      );
      if (res.code !== 200) {
        return;
      }
    },
    // 查询是否点过赞
    async getIfVant() {
      // 获取当前是否点赞
      // 0未点  1点了  likes()
      let res = await this.vantCont.methods
        .likes(this.store.user.address, this.store.actVideoId)
        .call({ from: Store.state.wallet });
      if (res == 1) {
        console.log("已经点赞 不可以点赞");
        this.ifVant = true;
      } else {
        console.log("没有点赞，可以点赞");
        this.ifVant = false;
      }
      return res;
    },
    // 点赞，通知后端
    async vant() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      if (this.ifVant) {
        return this.$message.warning("已经点赞");
      }
      let dt = await this.vantCont.methods
        .putLikesData(this.store.actVideoDec.id)
        .encodeABI();

      let trans = {
        from: this.store.user.address,
        to: this.store.vantCont,
        value: 0,
        data: dt,
      };

      console.log(trans);
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [trans],
        })
        .then(() => {
          this.$message.success("点赞成功");
          this.ifVant = true;
          this.setVcant();
          this.store.actVideoDec.loveNum++;
        })
        .catch(() => {
          return this.$message.error("点赞失败");
        });
    },
    // 通知后端点赞了
    async setVcant() {
      let { data: res } = await this.$http.post(`/videoInfo/video/love`, {
        userId: this.store.user.userId,
        videoId: this.store.actVideoDec.id,
      });
      if (res.code !== 200) {
        return;
      }
    },
    // 收藏视频/取消收藏视频
    async addLove() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let pat = "";
      if (this.store.actVideoDec.collected == 0) {
        pat = "/videoCollect/add";
        console.log("收藏");
      } else {
        pat = "/videoCollect/cancel";
        console.log("取消收藏");
      }
      let { data: res } = await this.$http.post(pat, {
        userId: this.store.user.userId,
        videoId: this.store.actVideoDec.id,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      if (pat == "/videoCollect/add") {
        this.store.actVideoDec.collected = 1;
      } else {
        this.store.actVideoDec.collected = 0;
      }
    },
    // 添加关注
    async addGZ() {
      let { data: res } = await this.$http.post("/userFollow/follow", {
        targetUserId: this.store.actVideoDec.userId,
        // targetUserId: "1527493285113892864",
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.store.actVideoDec.followed = 1;
      this.$message.success("关注成功");
    },
    // 取消关注
    async delGZ() {
      let { data: res } = await this.$http.post("/userFollow/cancelFollow", {
        targetUserId: this.store.actVideoDec.userId,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.store.actVideoDec.followed = 0;

      this.$message.success("取消关注成功");
    },
    // 打开打赏
    upReward() {
      this.$refs.rew.up();
    },
    // 分享，复制链接
    doCopy() {
      // this.$message.warning("暂未开放");
      // console.log(cp);
      var clipboard = new Clipboard(".hash");
      // console.log(clipboard);
      clipboard.on("success", () => {
        this.$message.success("分享链接已复制");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message.error("error");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  min-height: 100vh;
  // padding-bottom: (90 / 3.75vw);
  .videoBox {
    position: absolute;
    height: (250 / 3.75vw);
    video {
      width: (100vw);
      height: (211 / 3.75vw);
      background-color: #000;
    }
    // 隐藏进度条
    video::-webkit-media-controls-timeline {
      // display: none;
    }
    // 隐藏音量按钮
    video::-webkit-media-controls-mute-button {
      display: none !important;
    }
    // 隐藏音量控制
    video::-webkit-media-controls-volume-slider,
    video::-webkit-media-controls-mute-button {
      //隐藏android端video标签自带的音量调节按钮
      display: none;
    }
    /*其实这倒不是什么大问题，但是为了样式统一，以及方便监控事件，可以在css中取消这个按钮*/
    video::-webkit-media-controls-start-playback-button {
      //video视频不显示默认的中心的play icon
      opacity: 0;
    }
    // 总时间
    video::-webkit-media-controls-time-remaining-display {
    }
    video::-webkit-media-controls-seek-back-button {
      display: none;
    }
    video::-webkit-media-controls-seek-forward-button {
      display: none;
    }
    // 全屏按钮
    video::-webkit-media-controls-fullscreen-button {
      // display: none;
      // background-color: pink;
      // height: 20px;
    }
    video::-webkit-media-controls-rewind-button {
      display: none;
    }
    video::-webkit-media-controls-return-to-realtime-button {
      display: none;
    }
    video::-webkit-media-controls-toggle-closed-captions-button {
      display: none;
    }

    .video-player {
      .vjs-progress-control {
        display: none !important;
      }
    }
  }
  .actList {
    padding: (10 / 3.75vw) 2.5vw;
    padding-top: (10 / 3.75vw);
    margin-top: (-5 / 3.75vw);
    background-color: #f1f1f1;
    .item {
      display: inline-block;
      margin-right: (15 / 3.75vw);
      padding-bottom: (10 / 3.75vw);
      // border-bottom: 1px solid #000;
    }
    .act {
      border-bottom: 1px solid #000;
    }
  }
  .box2,
  .box1 {
    padding-top: (280 / 3.75vw);
    // overflow: auto;
  }
  .box1 {
    .videoDetails {
      padding: 0 (18 / 3.75vw);
      .user {
        display: flex;
        .name {
          flex: 1;
        }
        .wl-but {
          /* 矩形 3 */

          height: (28 / 3.75vw);
          border-radius: (14 / 3.75vw);
          background-color: #ff7d41;
          box-shadow: 0px 2px 3px 0px rgba(176, 176, 176, 0.5);
          font-size: (13 / 3.75vw);
          padding: 0 (15 / 3.75vw);
          display: inline-block;
          line-height: (28 / 3.75vw);
          color: #fff;
        }
        .but2 {
          border: 1px solid #ff7d41;
          background-color: #fff;
          color: #ff7d41;
        }
      }
      .videoName {
        font-size: (18 / 3.75vw);
        margin-top: (15 / 3.75vw);
        font-weight: bold;
        letter-spacing: 0em;
        color: #333333;
      }
      .videoJS {
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #333333;
      }
    }
    .operate {
      display: flex;
      justify-content: space-around;
      margin-top: (13 / 3.75vw);
      div {
        text-align: center;
        svg {
          display: block;
          margin: 0 auto;
          font-size: (20 / 3.75vw);
        }
        span {
          /* 打赏 */
          height: 17px;
          font-family: PingFang SC Medium;
          font-size: (12 / 3.75vw);
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;
        }
      }
    }
    .listName {
      padding-left: (18 / 3.75vw);
      margin-top: (10 / 3.75vw);
    }
  }
}
.tru {
  color: #ff7d41;
}
</style>
