<template>
  <div class="pl-item">
    <div class="plItem">
      <div class="user">
        <avatar-item :name="it.nickName" :addr="it.targetUserId"></avatar-item>
        <div class="textBox">
          <div class="name">{{ it.nickName }}</div>
          <div class="text">
            {{ it.contentText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatarItem from "../../components/avatar/avatar-item.vue";
export default {
  components: { avatarItem },
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.plItem {
  display: flex;
  margin-top: (13 / 3.75vw);
  .user {
    display: flex;
    .avatar {
      height: (40 / 3.75vw);
      width: (40 / 3.75vw);
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .textBox {
    flex: 1;
    margin-left: (40 / 3.75vw);

    .name {
      /* Bill·Clinton */
      height: (20 / 3.75vw);
      font-family: PingFang SC Medium;
      font-size: (14 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
    .text {
      /* 这是一段评论，这是一段评论，这是一段评论，这是一段评论。这是一段评论，这是一段评论这是一段评论，这是一段评论。这是一段评论，这是一段评论。这是一段评论，这是一段评论。 */

      font-family: PingFang SC Medium;
      font-size: (14 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
    }
  }
}
</style>
