<template>
  <div class="pl">
    <div class="topBox">
      <div class="name">{{ store.actVideoDec.videoTitle }}</div>
      <div class="num">评论:{{ total }}</div>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getVideoComList()"
    >
      <div
        class="plItem"
        v-for="(item, index) in store.actVideoComList"
        :key="index"
      >
        <div class="user">
          <avatar-item :name="item.nickName" :addr="item.userId"></avatar-item>
          <div class="textBox">
            <div class="name">{{ item.nickName }}</div>
            <div class="text">
              {{ item.contentText }}
            </div>
          </div>
          <!-- <div class="hfBut">回复</div> -->
        </div>
        <div class="hf" v-for="(it, ind) in item.children" :key="ind">
          <video-details-pl-item :it="it"></video-details-pl-item>
        </div>
      </div>
    </van-list>
    <div class="inpBox">
      <input type="text" v-model="input" placeholder="留下你的评论" />
      <div class="mBut" @click="comment()">发送</div>
    </div>
    <div class="hFinpBox inpBox" v-if="hfInp">
      <input type="text" v-model="input" placeholder="留下你的评论" />
      <div class="mBut" @click="comment()">发送</div>
    </div>
  </div>
</template>
<script>
import AvatarItem from "../../components/avatar/avatar-item.vue";
import Store from "../../store";
import videoDetailsPlItem from "./video-details-pl-item.vue";
export default {
  components: { videoDetailsPlItem, AvatarItem },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      input: "",

      list: [],
      loading: false,
      finished: false,

      total: 0,
      pageNum: 1,
      pageSize: 10,

      hfInp: true,
      // 恢复谁
      hfUser: {
        name: "",
        id: "",
      },
    };
  },
  mounted() {
    // 获取评论列表
    // this.getVideoComList();
    this.pageNum = 1;
    this.store.actVideoComList = [];
  },
  methods: {
    // 获取评论列表
    async getVideoComList() {
      let { data: res } = await this.$http.post(
        `/videoComment/videoDetail/list/${this.store.actVideoId}`,
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: 2,
          videoId: this.store.actVideoId,
        }
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.store.actVideoComList = this.uti.addArr(
        this.store.actVideoComList,
        res.data.records
      );
      this.loading = false;
      console.log(this.pageNum * this.pageSize >= this.total);
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.pageNum++;
      }
    },
    // 评论视频
    async comment() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      if (this.input == "") {
        return this.$message.error("评论不能为空");
      }
      // if (!word(this.input)) {
      //   return this.$message.error("标题包含敏感词汇");
      // }

      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/videoComment/videoDetail/send/comment`,
        {
          userId: this.store.user.userId,
          // 评论内容
          contentText: this.input,
          // 视频id
          videoId: this.store.actVideoDec.id,
        }
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.input = "";
      this.pageNum = 0;
      this.store.actVideoComList = [];
      this.getVideoComList();
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.pl {
  // padding-bottom: (30 / 3.75vw);
  .topBox {
    padding: 0 (18 / 3.75vw);
    margin-top: (10 / 3.75vw);
    .name {
      /* 演讲 */

      font-family: PingFang SC Bold;
      font-size: (18 / 3.75vw);
      font-weight: bold;
      letter-spacing: 0em;
      color: #333333;
    }
    .num {
      /* 评论：12 */

      font-family: PingFang SC Medium;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
  .van-list {
    margin-top: (11 / 3.75vw);
    padding: 0 (18 / 3.75vw);
  }
  .plItem {
    // display: flex;
    margin-top: (13 / 3.75vw);
    .user {
      display: flex;
      .avatar {
        height: (40 / 3.75vw);
        width: (40 / 3.75vw);
        border-radius: 50%;
        background-color: cornflowerblue;
        text-align: center;
        line-height: (40 / 3.75vw);
        color: #fff;
        // img {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 50%;
        // }
      }
      .text {
        word-break: break-all;
        width: (200 / 3.75vw);
      }
      .hfBut {
        line-height: (40 / 3.75vw);
      }
    }
    .hf {
      margin-left: (50 / 3.75vw);
    }
    .textBox {
      flex: 1;
      margin-left: (10 / 3.75vw);

      .name {
        /* Bill·Clinton */
        height: (20 / 3.75vw);
        font-family: PingFang SC Medium;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
      .text {
        /* 这是一段评论，这是一段评论，这是一段评论，这是一段评论。这是一段评论，这是一段评论这是一段评论，这是一段评论。这是一段评论，这是一段评论。这是一段评论，这是一段评论。 */

        font-family: PingFang SC Medium;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #333333;
      }
    }
  }
  .inpBox {
    position: fixed;
    // bottom: (65 / 3.75vw);
    bottom: 0;
    left: 0;
    background-color: #f9f9f9;
    width: 100vw;
    padding: (10 /3.75vw) (18 / 3.75vw);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: (12 / 3.75vw);
    .mBut {
      margin-left: (15 / 3.75vw);
      // background: none;
      // background-color: #f5f5f5;
    }
    input {
      /* 矩形 6 */
      flex: 1;
      width: (10 / 3.75vw);
      height: (42 / 3.75vw);
      border-radius: (21 / 3.75vw);
      background: #ffffff;
      border: none;
      box-shadow: 0px 0px 3px 0px rgba(178, 178, 178, 0.5);
      padding: 0 (15 / 3.75vw);
    }
  }
}
@media screen and (max-height: 567px) {
  .pl {
    .inpBox {
      bottom: 0;
    }
  }
}
</style>
